import { ThemeProvider } from "@mui/material/styles";
import "styles/base.scss";
import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import theme from "theme";
import { store } from "store/store";
import AppRoutes from "router/AppRoutes";

export const toastErrorConfig = {
  position: "bottom-center",
  autoClose: 3000,
  draggable: false,
  theme: "colored",
  closeOnClick: true,
  pauseOnHover: true,
  newestOnTop: false,
  rtl: false,
  pauseOnFocusLoss: true,
  transition: Slide,
  hideProgressBar: true,
};

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
      <ToastContainer {...toastErrorConfig} />
    </Provider>
  );
}

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loader from "components/Loader";

import { ROUTES } from "./routes";
import PublicLayout from "./PublicLayout";
import PrivateLayout from "./PrivateLayout";

// Unauthorized
const Login = lazy(() => import("pages/Login"));
const Auth = lazy(() => import("pages/Auth"));

// Authorized
const Dashboard = lazy(() => import("pages/Dashboard"));
const Home = lazy(() => import("pages/Home"));
const Patient = lazy(() => import("pages/Patient"));
const LiveSession = lazy(() => import("pages/LiveSession"));
const LiveSessionAlt = lazy(() => import("pages/LiveSessionAlt"));
const WeeklyCalendar = lazy(() => import("pages/Calendar/daily-calendar"));
const RecurringCalendar = lazy(() => import("pages/Calendar/recurring-slots"));

const router = createBrowserRouter([
  {
    element: <PublicLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.AUTH,
        element: <Auth />,
      },
      {
        path: ROUTES.DEMO,
        element: <LiveSessionAlt />,
      },
    ],
  },
  {
    element: <PrivateLayout />,
    children: [
      {
        element: <Dashboard />,
        children: [
          {
            path: `${ROUTES.LIVESESSION}/:sessionId`,
            element: <LiveSession />,
          },
          {
            path: ROUTES.HOME,
            element: <Home />,
          },
          {
            path: ROUTES.WEEKLYCALENDAR,
            element: <WeeklyCalendar />,
          },
          {
            path: ROUTES.RECURRINGCALENDAR,
            element: <RecurringCalendar careproviderId="me" />,
          },
          {
            path: ROUTES.PATIENTS,
            element: <Patient />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Login />,
  },
]);

export default function AppRoutes() {
  return (
    <Suspense fallback={<Loader height="100vh" />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

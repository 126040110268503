import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { loginWithPassword, userInfo } from "./services/auth";
import { livesessions } from "./services/livesession";
import { notes } from "./services/livesessionnotes";
import { sessions } from "./services/session";
import { constants } from "./services/constant";
import { messages } from "./services/message";
import { calendars } from "./services/calendar";

const slices = [
  loginWithPassword,
  userInfo,
  livesessions,
  sessions,
  constants,
  messages,
  calendars,
  notes,
];

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    ...slices.reduce((acc, cv) => {
      return {
        ...acc,
        [cv.reducerPath]: cv.reducer,
      };
    }, {}),
    // [loginWithPassword.reducerPath]: loginWithPassword.reducer,
    // [userInfo.reducerPath]: userInfo.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(slices.map((slice) => slice.middleware)),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

import { Box, CircularProgress, Typography } from "@mui/material";

export default function Loader({ height, content = "Loading..." }) {
  return (
    <Box
      display="flex"
      height={height || "90%"}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
      <Typography sx={{ marginTop: 10 }}>{content}</Typography>
    </Box>
  );
}

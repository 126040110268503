import {
  Navigate,
  Outlet,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { getAuthCookie } from "helpers/utils";
import { ROUTES } from "./routes";

export default function PublicLayout() {
  const token = getAuthCookie();
  const [queryParams] = useSearchParams();
  const redirectUrl = queryParams.get("redirectUrl");
  const { pathname } = useLocation();

  if (token && redirectUrl) {
    return <Navigate replace={redirectUrl} />;
  }
  if (token && !pathname.startsWith("/public")) {
    return <Navigate to={ROUTES.DASHBOARD} />;
  }
  return <Outlet />;
}

import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const sessions = createApi({
  reducerPath: "session",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getUpcomingSessions: builder.query({
      query: () => ({
        url: "/care-provider/me/schedule/upcoming",
      }),
    }),
    joinSession: builder.mutation({
      query: (data) => ({
        url: "/live/session/care-provider/join",
        method: "POST",
        data,
      }),
    }),
    updateIntensity: builder.mutation({
      query: (data) => ({
        url: "/live/session/schedule/change-session-intensity",
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useLazyGetUpcomingSessionsQuery,
  useJoinSessionMutation,
  useUpdateIntensityMutation,
} = sessions;

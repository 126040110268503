const name = "careTk";
const getDomain = () => {
  if (window.location.origin.includes("localhost")) {
    return "localhost";
  }
  return window.location.origin.split("//")[1];
  // return window.location.origin.split(".").slice(-2).join(".");
};

export const setAuthCookie = (value, days = 1) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${
    value || ""
  }${expires};domain=${getDomain()};path=/`;
};

export const removeAuthCookie = () => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${getDomain()};`;
};

export const getAuthCookie = () => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const handleAuthentication = (token, callback) => {
  setAuthCookie(token);
  callback?.();
};

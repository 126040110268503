export const ROUTES = {
  LOGIN: "/login",
  AUTH: "/auth",

  DASHBOARD: "/",
  PATIENTS: "/patients",
  HOME: "/",
  LIVESESSION: "/live",
  CUSTOMLIVE: "/customlive",
  DEMO: "/public/demo/:hostRoomCode",

  WEEKLYCALENDAR: "/weekly-calendar",
  RECURRINGCALENDAR: "/recurring-calendar",
};

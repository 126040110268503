import axios from "axios";
import { toast } from "react-toastify";
import { removeAuthCookie, getAuthCookie } from "helpers/utils";

const defaultOptions = (withAuth) => ({
  headers: {
    Authorization: withAuth ? `Bearer ${getAuthCookie() || ""}` : "",
  },
});

export const axiosInstance = (withAuth = true) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    ...defaultOptions(withAuth),
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Logout user if unauthorized
      if (error?.response?.status === 401) {
        toast.error("You are not logged in. Please login and try again");
        removeAuthCookie();
      } else {
        // show toast error when error comes
        toast.error(
          error?.response?.data?.message || error?.message || "Retry again.",
          {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          },
        );
      }
      throw error;
    },
  );
  return instance;
};

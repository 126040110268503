import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

// Define a service using a base URL and expected endpoints
export const constants = createApi({
  reducerPath: "constant",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAllCourses: builder.query({
      query: () => ({
        url: "/curriculum/course",
      }),
    }),
  }),
});

export const { useLazyGetAllCoursesQuery } = constants;

import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

// Define a service using a base URL and expected endpoints
export const notes = createApi({
  reducerPath: "note",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    addOrUpdatePatientNotes: builder.mutation({
      query: ({ patientId, id, data }) => ({
        url: `/patient/${patientId}/patient-notes/${id || ""}`,
        method: id ? "PUT" : "POST",
        data,
      }),
    }),
    getPatientNotes: builder.mutation({
      query: ({ patientId, params }) => ({
        url: `/patient/${patientId}/patient-notes`,
        params,
      }),
    }),
  }),
});

export const {
  useAddOrUpdatePatientNotesMutation,
  useGetPatientNotesMutation,
} = notes;

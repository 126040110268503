import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

// Define a service using a base URL and expected endpoints
export const messages = createApi({
  reducerPath: "message",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    updateLastSeenForPatient: builder.mutation({
      query: ({ patientId }) => ({
        url: `/message/patient/${patientId}/last-seen`,
        method: "POST",
      }),
    }),
    getUnReadForPatients: builder.mutation({
      query: (data) => ({
        url: `/message/groups/unread-details`,
        data,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useUpdateLastSeenForPatientMutation,
  useGetUnReadForPatientsMutation,
} = messages;
